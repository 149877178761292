<template>
    <v-autocomplete
        v-bind="$attrs"
        v-on="$listeners"
        background-color="#EDF2F9"
        outlined
        class="mt-2"
        hide-no-data
        hide-details
        hide-selected
        item-text="label"
        placeholder="Pickup city"
        return-object
    ></v-autocomplete>
</template>

<script>
import geodataMixin from '@/mixins/geodataMixin'

export default {
    mixins: [geodataMixin],
}
</script>

<style lang="scss">
</style>
