<template>
    <v-stepper-content step="7" class="px-0">
        <v-form lazy-validation ref="form7">
            <h2 class="text-28-60 my-4">What is the pick-up address?</h2>
            <v-row>
                <!-- <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">First Name</label>
                        <v-text-field v-model="form.from.first_name" placeholder="First Name" background-color="silver" outlined flat hide-details="auto"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Last Name</label>
                        <v-text-field v-model="form.from.last_name" placeholder="Last Name" background-color="silver" outlined flat hide-details="auto"></v-text-field>
                    </div>
                </v-col> -->
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Company or individual name</label>
                        <v-text-field
                            v-model="form.from.name"
                            placeholder="Company or individual name"
                            background-color="silver"
                            outlined
                            flat
                            hide-details="auto"
                            :rules="requiredRules"
                            required
                        ></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Pickup address</label>
                        <v-text-field
                            v-model="form.from.address"
                            placeholder="Pickup address"
                            background-color="silver"
                            outlined
                            flat
                            hide-details="auto"
                            :rules="requiredRules"
                            required
                        ></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Address 2 (optional)</label>
                        <v-text-field v-model="form.from.address2" placeholder="Address 2 (optional)" background-color="silver" outlined flat hide-details="auto"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <div class="d-flex justify-space-between">
                            <label for="" class="text-14-45">City or postal code</label>
                            <span v-if="!change" @click="change = true" class="blue-text text-14-45" style="cursor:pointer">Change</span>
                        </div>
                        <v-text-field v-if="!change" v-model="form.from.city_label" placeholder="City or postal code" background-color="silver" outlined flat hide-details="auto" readonly></v-text-field>
                        <city-autocomplete
                            v-if="change"
                            v-model="form.from.geoModel"
                            :items="form.from.geoItems"
                            :search-input.sync="form.from.geoSearch1"
                        />
                    </div>
                </v-col>
            </v-row>
            <h2 class="text-28-60 my-4">Carrier pick-up information</h2>
            <v-row>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Requested loading date</label>
                        <v-menu
                            v-model="form.from.datepicker2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="computedDateFormatted"
                                    class="mt-2"
                                    outlined
                                    background-color="silver"
                                    placeholder="08/23/2020"
                                    append-icon="mdi-calendar-month"
                                    hide-details="auto"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="form.from.date"
                                no-title
                                @input="form.from.datepicker2 = false"
                            ></v-date-picker>
                        </v-menu>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Pick-up reference number</label>
                        <v-text-field v-model="form.wo" placeholder="Optional" background-color="silver" outlined flat hide-details="auto"></v-text-field>
                    </div>
                </v-col>
            </v-row>
            <p class="text-14-45 silver--text">Subject to carrier acceptance and availability</p>
            <v-row>
                <v-col cols="12" lg="8">
                    <div class="">
                        <label for="" class="text-14-45">Requested pickup window</label>
                        <div class="d-flex align-center">
                                <v-select v-model="from_time" :items="hours" background-color="silver" label="Choose packaging type" outlined flat solo hide-details="auto"></v-select>
                            <p class="px-2 mb-0">to:</p>
                            <v-select v-model="to_time" :items="hours" background-color="silver" label="Choose packaging type" outlined flat solo hide-details="auto"></v-select>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <p class="text-14-45 silver--text">3 hour minimum - Pickups generally occur in the afternoon</p>
            <div class="mb-8">
                <label for="" class="text-14-45">Special instructions (optional)</label>
                <v-textarea
                    v-model="form.from.instruction"
                    background-color="silver"
                    rows="3"
                    outlined
                    hide-details="auto"
                    flat
                ></v-textarea>
            </div>
            <h2 class="text-28-60 my-4">Contact person</h2>
            <v-row>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Contact name</label>
                        <v-text-field
                            v-model="form.from.contact_name"
                            placeholder="Contact name"
                            background-color="silver"
                            outlined
                            flat
                            hide-details="auto"
                            :rules="requiredRules"
                            required
                        ></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Phone</label>
                        <v-text-field
                            v-model="form.from.contact_phone1"
                            placeholder="Phone"
                            background-color="silver"
                            outlined
                            flat
                            hide-details="auto"
                            :rules="phoneRules"
                            required
                        ></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Email</label>
                        <v-text-field
                            v-model="form.from.contact_email"
                            placeholder="Email"
                            background-color="silver"
                            outlined
                            flat
                            hide-details="auto"
                            :rules="emailRules"
                            required
                        ></v-text-field>
                    </div>
                </v-col>
            </v-row>
            <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none" @click="$emit('next')" depressed>Next step <v-icon>mdi-chevron-right</v-icon></v-btn>
        </v-form>
    </v-stepper-content>
</template>

<script>
import CityAutocomplete from '@/components/base/CityAutocomplete';
import ValidationMixin from '@/mixins/validationMixin.js';

export default {
    props: ['form'],
    components: {
        CityAutocomplete
    },
    mixins: [
        ValidationMixin
    ],
    data: () => ({
        hours: [],
        from_time: '',
        to_time: '',
        change: false
    }),
    mounted(){
        this.makeHours()
    },
    computed: {
        computedDateFormatted () {
            return this.formatDate(this.form.from.date)
        },
    },
    watch: {
        from_time (val) {
            this.setTimeInterval();
        },
        to_time (val) {
            this.setTimeInterval();
        }
    },
    methods: {
        makeHours(){
            let type = ['AM', 'PM'];
            var i;
            type.forEach((val) => {
                // for(i = 1; i <= 12; i++){
                //     console.log( i + ' ' + val);
                // }
                for (i = 1; i <= 12; i++) {
                  this.hours.push(i + ' ' + val)
                }
            })
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        setTimeInterval(){
            console.log('intrval');
            if(this.from_time && !this.to_time)
                this.form.from.time = this.from_time;
            if(!this.from_time && this.to_time)
                this.form.from.time = this.to_time;
            if(this.from_time && this.to_time)
                this.form.from.time = this.from_time + '-' + this.to_time;
        }
    }
}
</script>
