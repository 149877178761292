<template>
    <v-stepper-content step="5" class="px-0 step5">
        <div class="my-10 d-flex flex-column justify-center">
            <div class="p-relative parentlogo">
                <img class="logo" src="@/assets/logo/logo-mini-dark.svg" alt="">
                <img class="rotate logo" src="@/assets/logo/preloading.svg" alt="">
            </div>
            <div class="text-28-60 text-center my-5">
                Calculating your quotes <br/>with different carriers
            </div>
        </div>
    </v-stepper-content>
</template>

<script>
export default {
}
</script>

<style lang="scss">
.step5{
    .parentlogo{
        height: 150px;
    }
    .logo{
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
    }
    .rotate{
        -webkit-animation: rotating 5s linear infinite;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
    }
    @-webkit-keyframes rotating {
        from{
            -webkit-transform: rotate(0deg);
        }
        to{
            -webkit-transform: rotate(360deg);
        }
    }
}
</style>
