<template>
    <div class="calculator mt-5">
        <v-row>
            <v-col cols="12" lg="7">
                <v-card class="px-md-10 py-7" flat>
                    <v-stepper v-model="e1" class="box-shadow-none">
                        <div v-if="e1 < 4" class="stepper-header d-flex">
                            <div v-for="(tab, key) in tabs" class="flex-grow-1 flex-shrink-0 singlestep">
                                <div class="text-14-60 text-uppercase mb-3">{{ tab }}</div>
                                <div v-if="e1 <= key" class="d-flex align-center">
                                    <img src="@/assets/icons/svg/blue-point-inactive.svg" alt="">
                                    <div class="dot-line w-100"></div>
                                </div>
                                <div v-else class="d-flex align-center">
                                    <img src="@/assets/icons/svg/blue-point.svg" alt="">
                                    <div class="line w-100"></div>
                                </div>
                            </div>
                        </div>
                        <div v-if="e1 > 6 && e1 < 20" class="stepper-header d-flex">
                            <div v-for="(tab, key) in tabs2" class="flex-grow-1 flex-shrink-0 singlestep">
                                <div class="text-14-60 text-uppercase">{{ tab }}</div>
                                <div v-if="e1 <= key + 6" class="d-flex align-center">
                                    <img src="@/assets/icons/svg/blue-point-inactive.svg" alt="">
                                    <div class="dot-line w-100"></div>
                                </div>
                                <div v-else class="d-flex align-center">
                                    <img src="@/assets/icons/svg/blue-point.svg" alt="">
                                    <div class="line w-100"></div>
                                </div>
                            </div>
                        </div>
                        <v-stepper-items>
                            <calculator-step1 ref="step1" :form="form" @next="nextStep()"/>
                            <calculator-step2 ref="step2" :form="form" @next="nextStep()" @prev="prevStep()"/>
                            <calculator-step3 ref="step3" :form="form" :item_push="item_push" @next="getQuotePrice()" @prev="prevStep()"/>
                            <calculator-step4 ref="step4" :form="form" @getQuote="getQuotePrice()" @prev="prevStep()"/>
                            <calculator-step5 />
                            <calculator-step6 ref="step6" :form="form" @makeQuote="storeQuote()" @backTo="backTo" />
                            <calculator-step7 ref="step7" :form="form" @next="nextStep()" @prev="prevStep()"/>
                            <calculator-step8 ref="step8" :form="form" @next="nextStep()" @prev="prevStep()"/>
                            <calculator-step9 ref="step9" :form="form" @next="nextStep()" @prev="prevStep()"/>
                            <calculator-step10 ref="step10" :form="form" @next="nextStep()" @prev="prevStep()" @makeOrder="makeOrder()"/>
                            <calculator-step99 ref="step99" @saveUser="saveUser()"/>



                            <v-stepper-content step="11" class="px-0">

                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card>
            </v-col>
            <v-col class="d-none d-md-block" cols="12" lg="5">
                <calculator-summary :form="form" :location_types="location_types" :additional_services="additional_services"/>
            </v-col>
        </v-row>
        <!-- <calculator-step1 :form="form" @next="nextStep()" @prev="prevStep()" @makeOrder="makeOrder()"/> -->

    </div>
</template>

<script>
import Vue from 'vue';
import CalculatorSummary from '@/components/pages/Summary.vue';
import geodataMixin from '@/mixins/geodataMixin'
import CalculatorStep1 from '@/components/pages/calculator/Step1'
import CalculatorStep2 from '@/components/pages/calculator/Step2'
import CalculatorStep3 from '@/components/pages/calculator/Step3'
import CalculatorStep4 from '@/components/pages/calculator/Step4'
import CalculatorStep5 from '@/components/pages/calculator/Step5'
import CalculatorStep6 from '@/components/pages/calculator/Step6'
import CalculatorStep7 from '@/components/pages/calculator/Step7'
import CalculatorStep8 from '@/components/pages/calculator/Step8'
import CalculatorStep9 from '@/components/pages/calculator/Step9'
import CalculatorStep10 from '@/components/pages/calculator/Step10'
import CalculatorStep99 from '@/components/pages/calculator/Step99'
// import CalculatorStep11 from '@/components/pages/calculator/Step11'
export default {
    name: 'Calculator',
    props: ['setStep', 'formdata', 'redir'],
    mixins: [geodataMixin],
    components: {
        CalculatorSummary,
        CalculatorStep1,
        CalculatorStep2,
        CalculatorStep3,
        CalculatorStep4,
        CalculatorStep5,
        CalculatorStep6,
        CalculatorStep7,
        CalculatorStep8,
        CalculatorStep9,
        CalculatorStep10,
        CalculatorStep99,
        // CalculatorStep11,
    },
    data: () => ({
        id: null,
        e1: 1,
        tabs: [
            'Pickup',
            'Delivery',
            'Item Details',
            'freight class',
        ],
        tabs2: [
            'Pickup',
            'Delivery',
            'value',
            'Coverage',
        ],
        form: {
            id: null,
            from:{
                id: 0,
                zip: '',
                location_type: '',
                additional_services: [],
                city: '',
                date: new Date().toISOString().substr(0, 10),
                first_name: '',
                last_name: '',
                name: '',
                address: '',
                address2: '',
                wo: '',
                from_time: '',
                to_time: '',
                instruction: '',
                contact_name: '',
                contact_phone1: '',
                contact_email: '',
                datepicker: false,
                datepicker2: false,
            },
            to:{
                zip: '',
                location_type: '',
                additional_services: [],
                city: '',
                name: '',
                address: '',
                address2: '',
                instruction: '',
                contact_name: '',
                contact_phone1: '',
                contact_email: '',
            },
            items: [],
            hazardous: 0,
            temperature: 0,
            submited: false,
            insurance: 0,
        },
        item_push: {
            title: '',
            is_new: '',
            type: '',
            length: '',
            width: '',
            height: '',
            weight: '',
            quantity: '',
            class: null,
            nmfc: '',
        },
        estimate_price: [],
        location_types: {
            1: {
                key: 1,
                name: 'Business',
                desc: 'Commercial buildings and areas',
            },
            2: {
                key: 2,
                name: 'Residential',
                desc: 'Home and home businesses'
            },
            3: {
                key: 3,
                name: 'Drop off at a carrier terminal',
                desc: 'Save money and skip the pickup services'
            }
        },
        additional_services: {
            1: {
                key: 1,
                only: 'all',
                name: 'Lift gate',
                desc: 'When the location doesn\'t have a loading dock and your shipment is too heavy to lift without assistance'
            },
            2: {
                key: 2,
                only: 'up',
                name: 'Pickup inside the location',
                desc: 'When the driver needs to move the shipment from somewhere other than directly behind the truck'
            },
            3: {
                key: 3,
                only: 'up',
                name: 'Limited access',
                desc: 'A non-residential location that may be difficult for a truck to access. i.e. farms, construction site, church, strip mall, school, etc.'
            },
            4: {
                key: 4,
                only: 'down',
                name: 'Delivery inside the location',
                desc: 'When the driver needs to move the shipment from somewhere other than directly behind the truck'
            },
            5: {
                key: 5,
                only: 'down',
                disabled: true,
                name: 'Appointment Fee',
                desc: 'When the carrier is required to contact final delivery location for specific appointment window'
            },
            6: {
                key: 6,
                only: 'down',
                name: 'Residential delivery',
                desc: 'When the location is a residential address'
            },
        },
    }),
    created(){

    },
    mounted(){
        if(this.$store.getters['calculator/form']){
            if(this.$store.state.calculator.form.submited){
                if(this.redir){
                    this.$router.push({name: 'account.quote'})
                }else{
                    this.makeQuote()
                }

            }
        }
        if(this.$store.state.calculator.form){
            for(const [key, value] of Object.entries(this.form)){
                if(this.$store.state.calculator.form[key])
                    this.form[key] = Object.assign({}, this.form[key], this.$store.state.calculator.form[key]);
            }
            // this.form = Object.assign({}, this.form, this.$store.state.calculator.form);
        }
        if(this.$route.params.id){
            // console.log(this.$route.params.id);
            this.id = this.$route.params.id
            this.getQuote();
            this.checkUser();
        }
    },
    computed: {},
    watch: {
        // 'calculator.date' (val) {
        //     this.form.from.dateFormatted = this.formatDate(this.form.from.date)
        // },
    },
    methods: {
        checkUser(){
            if(!this.$store.state.auth.user.first_name || !this.$store.state.auth.user.last_name){
                this.e1 = 99;
                window.scrollTo(0,0);
            }
        },
        async getQuotePrice(){
            this.e1 += 2;
            // await this.axios.get(process.env.VUE_APP_API + 'countries/v1/distance/?from=' + )
            setTimeout(() => this.e1 += 1, 3000);
            window.scrollTo(0,0);
            // setTimeout(this.e1 += 1, 2000);
            // this.e1 += 1;
        },
        async storeQuote(){
            this.form.submited = true;

            this.$store.dispatch('calculator/setForm', {
                form: this.form
            });

            if(this.redir){
                this.$router.push({name: 'account.quote'})
            }else{
                this.makeQuote()
            }
        },
        async makeQuote(){
            if(this.$store.getters['auth/id'] && this.$store.state.calculator.form.submited){
                var form = this.$store.getters['calculator/form'];
                form.id_customer = this.$store.getters['auth/id'];

                await this.axios.post(process.env.VUE_APP_API + 'loads/v1/load', form).then(response => {
                    this.form.id = response.data;
                    this.id = response.data;

                });
                // this.$store.dispatch('calculator/removeForm');
                // this.$store.dispatch('calculator/removeFlag');

                this.getQuote();
            }


        },
        async saveUser(){
            this.axios.put(process.env.VUE_APP_API + 'customers/v1/customer/' + this.$store.state.auth.user.id, this.$store.state.auth.user).then(response => {
                if(response.data)
                    this.e1 = 7;
                    window.scrollTo(0,0);
            });
        },
        getQuote(){
            this.axios.get(process.env.VUE_APP_API + 'loads/v1/load/' + this.id).then(response => {
                this.form = {
                    id: response.data.id,
                    id_status: 3,
                    from:{
                        id: response.data.items[0].warehouse.id,
                        date: new Date(response.data.items[0].date).toISOString().substr(0, 10),
                        // date: this.formatDate(response.data.items[0].date),new Date(
                        location_type: response.data.items[0].location_type,
                        additional_services: response.data.items[0].additional,
                        city: response.data.items[0].warehouse.city,
                        zip: response.data.items[0].warehouse.zip,
                        state: response.data.items[0].warehouse.state,
                        city_label: response.data.items[0].warehouse.state + ', ' + response.data.items[0].warehouse.city + ', ' + response.data.items[0].warehouse.zip,

                        first_name: '',
                        last_name: '',
                        name: '',
                        address: '',
                        address2: '',
                        ref_number: '',
                        from_time: '',
                        to_time: '',
                        instruction: '',
                        contact_name: '',
                        contact_phone: '',
                        contact_email: '',
                        datepicker: false,
                        datepicker2: false,


                        // id: response.data.items[0].id,
                        // date: this.formatDate(response.data.items[0].date),
                        //
                        // zip: response.data.items[0].warehouse.zip,
                        // location_type: response.data.items[0].location_type,
                        // additional_services: response.data.items[0].additional,
                        // city: response.data.items[0].warehouse.city,
                        // datepicker: false,
                        // datepicker2: false,
                        // dateFormatted: new Date().toISOString().substr(0, 10),
                        // company_name: response.data.items[0].warehouse.name,
                        // address: response.data.items[0].warehouse.address,
                        // address2: response.data.items[0].warehouse.address2,
                        // ref_number: '',
                        // from_time: '',
                        // to_time: '',
                        // instruction: '',
                        // contact_name: '',
                        // contact_phone: '',
                        // contact_email: '',
                    },
                    to:{
                        id: response.data.items[1].warehouse.id,
                        date: new Date(response.data.items[1].date).toISOString().substr(0, 10),
                        // date: this.formatDate(response.data.items[1].date),
                        location_type: response.data.items[1].location_type,
                        additional_services: response.data.items[1].additional,
                        city: response.data.items[1].warehouse.city,
                        zip: response.data.items[1].warehouse.zip,
                        state: response.data.items[1].warehouse.state,
                        city_label: response.data.items[1].warehouse.state + ', ' + response.data.items[1].warehouse.city + ', ' + response.data.items[1].warehouse.zip,

                        first_name: '',
                        last_name: '',
                        name: '',
                        address: '',
                        address2: '',
                        ref_number: '',
                        from_time: '',
                        to_time: '',
                        instruction: '',
                        contact_name: '',
                        contact_phone: '',
                        contact_email: '',
                        datepicker: false,
                        datepicker2: false,
                        //
                        // id: response.data.items[0].id,
                        // zip: response.data.items[0].warehouse.zip,
                        // location_type: response.data.items[0].location_type,
                        // additional_services: response.data.items[0].additional,
                        // city: response.data.items[1].warehouse.id_city,
                        // company_name: '',
                        // address: response.data.items[1].warehouse.address,
                        // address2: response.data.items[1].warehouse.address2,
                        // instruction: '',
                        // contact_name: '',
                        // contact_phone: '',
                        // contact_email: '',
                    },
                    items: [],
                    hazardous: null,
                    temperature: null,
                    submited: false,
                    insurance: true,
                }

                var items = this.filterItems(response.data);

                items.forEach((item) => {
                    var itm = {
                        id: [item.id, item.id + 1],
                        title: item.title,
                        is_new: item.is_new,
                        type: item.type,
                        length: item.length,
                        width: item.width,
                        height: item.height,
                        weight: item.weight,
                        quantity: item.quantity,
                        class: item.class,
                        nmfc: item.nmfc,
                    }
                    this.form.items.push(Vue.util.extend({}, itm));
                });

                this.$store.dispatch('calculator/setForm', {
                    form: this.form
                });
                this.e1 = 7;
                this.checkUser();
            })
        },

        makeOrder(){
                var form = this.$store.getters['calculator/form'];
                    form.id_customer = this.$store.getters['auth/id'];

                this.axios.put(process.env.VUE_APP_API + 'loads/v1/load', form)
                this.$store.dispatch('calculator/removeForm');
                this.$router.push({name: 'account.order', params: {id: form.id}})
        },
        nextStep(){
            if(this.$refs['step' + this.e1].$refs['form' + this.e1].validate()){
                this.e1 += 1;
                window.scrollTo(0,0);
            }
        },
        prevStep(){
            this.e1 -= 1;
            window.scrollTo(0,0);
        },
        backTo(val){
            this.e1 = val;
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        filterItems(model){
            let items = model.items.filter(function(item){
                return item.is_shipper == 1;
            })
            return items
        },

    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
.calculator{
    .v-card{
        .v-input, textarea{
            margin-top: 7px;
        }
        .v-text-field{
            fieldset{
                border: none;
            }
            input::placeholder{
                font-size: 14px !important;
                color: $silver-dark !important;
            }
        }
    }
    .stepper-header{
        .line{
            border-bottom: 2px solid #5496F0;
        }
        .dot-line{
            border-bottom: 2px dashed #CDE0FF;
        }
    }
    .v-stepper__content{
        .previous{
            background-color: #ECECEC!important;
            color: $silver-dark;
        }
    }
    .selectitem{
        border: 1px solid #CDE0FF;
        box-sizing: border-box;
        border-radius: 5px;
    }
    .selectitem.active{
        background-color: #EDF2F9;
    }
    .selectitem.disabled{
        border: 1px solid #ECECEC;
        box-sizing: border-box;
        border-radius: 5px;
        h4, p {
            color: #808080;
        }
    }
    .previous{
        background: #ECECEC;
        border-radius: 5px;
    }
    label{
        color: #1F1F1F!important;
    }
    .border{
        border: 1px solid #CDE0FF;
        border-radius: 10px;
    }
    .badge{
        border-radius: 63px;
        border: 1px solid #CDE0FF;
        box-sizing: border-box;
    }
    .badge.active{
        background-color: $blue_first;
        color: white;
    }
}
.singlestep{
    min-width: 100px;
}
@media all and (max-width: 960px){
    .bg-light{
        background-color: #ffffff;
    }
    .calculator{
        .w-50, .w-70{
            width: auto;
        }
        .text-28-60{
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
        }
    }
}
</style>
