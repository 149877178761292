<template>
    <div class="summary">
        <v-card flat class="px-6 py-3">
            <v-card-text class="summary">
                <h2 class="text-28-60 mb-4">Quote Summary</h2>
                <h4 class="text-16-60">Pickup</h4>
                <v-row>
                    <v-col v-if="form.from.city" cols="12" lg="6">
                        <div class="item">
                            <div class="text-14-60">
                                City
                            </div>
                            <div class="text-14">
                                {{ form.from.city }}, {{form.from.state}} {{form.from.zip}}
                            </div>
                        </div>
                    </v-col>
                    <v-col v-if="form.from.date" cols="12" lg="6">
                        <div class="item">
                            <div class="text-14-60">
                                Requested loading date
                            </div>
                            <div class="text-14">
                                {{ form.from.date }}
                            </div>
                        </div>
                    </v-col>
                    <v-col v-if="form.from.additional_services[0]" cols="12" lg="6">
                        <div class="item">
                            <div class="text-14-60">
                                Additional services
                            </div>
                            <div v-for="item in form.from.additional_services" class="text-14">
                                {{ additional_services[item].name }}
                            </div>
                        </div>
                    </v-col>
                    <v-col v-if="form.from.location_type" cols="12" lg="6">
                        <div class="item">
                            <div class="text-14-60">
                                Type of location
                            </div>
                            <div v-if="location_types[form.from.location_type]" class="text-14">
                                {{ location_types[form.from.location_type].name }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <h4 class="text-16-60">Delivery</h4>
                <v-row>
                    <v-col v-if="form.to.city" cols="12" lg="6">
                        <div class="item">
                            <div class="text-14-60">
                                City
                            </div>
                            <div class="text-14">
                                {{ form.to.city }}, {{form.to.state}} {{form.to.zip}}
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <div class="item">
                            <div class="text-14-60">
                                Requested loading date
                            </div>
                            <div class="text-14">
                                {{ form.from.date }}
                            </div>
                        </div>
                    </v-col>
                    <v-col v-if="form.to.additional_services[0]" cols="12" lg="6">
                        <div class="item">
                            <div class="text-14-60">
                                Additional services
                            </div>
                            <div v-for="item in form.to.additional_services" class="text-14">
                                {{ additional_services[item].name }}
                            </div>
                        </div>
                    </v-col>
                    <v-col v-if="form.to.location_type" cols="12" lg="6">
                        <div class="item">
                            <div class="text-14-60">
                                Type of location
                            </div>
                            <div v-if="location_types[form.to.location_type]" class="text-14">
                                {{ location_types[form.to.location_type].name }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <h4 v-if="form.items[0]" class="text-16-60">Items</h4>
                <div v-if="form.items[0]" v-for="(item, key) in form.items" class="my-4">
                    <div class="text-14-60 mb-1" v-html="item.description"></div>
                    <div class="text-14 mb-5">
                        {{ item.quantity }} Crate, Total {{ item.quantity * item.weight}} lbs<span>, Class {{item.class}}</span>
                    </div>
                </div>
                <!-- <h4 class="text-16-60">Carrier</h4>
                <v-row>
                    <v-col cols="12" lg="4">
                        <div class="item">
                            <div class="text-14-60">
                                Type
                            </div>
                            <div class="text-14">
                                {{ form.to.city }}, {{form.to.state}} {{form.to.zip}}
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <div class="item">
                            <div class="text-14-60">
                                Carier
                            </div>
                            <div class="text-14">
                                {{ form.from.date }}
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <div class="item">
                            <div class="text-14-60">
                                Estimated transit
                            </div>
                            <div v-for="item in form.to.additional_services" class="text-14">
                                {{ additional_services[item].name }}
                            </div>
                        </div>
                    </v-col>
                </v-row> -->
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'Summary',
    props: ['form', 'location_types', 'additional_services']
}
</script>

<style lang="scss">
</style>
