<template>
    <div class="h-100">
        <div class="account h-100">

            <v-navigation-drawer app floating permanent :mini-variant="mini" :expand-on-hover="expandhover" mini-variant-width="100" v-if="$vuetify.breakpoint.lgAndUp">
                <v-list
                nav
                class="px-0 pt-6 accountmenu"
                >
                    <v-list-item :ripple="false">
                        <v-list-item-content class="pl-6">
                            <router-link :to="{ name: 'frontend.home', params: {} }">
                                <v-list-item-title>
                                    <img src="@/assets/logo/newblack.svg" width="147" alt="" class="expanded">
                                    <img src="@/assets/logo/logo-mini-white.svg" alt="" class="mini">
                                </v-list-item-title>
                            </router-link>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item-group v-model="current">
                        <v-list-item v-for="item in lgAndUpMenu()" active-class="menuactive" class="mb-0" :ripple="false" :to="{ name: item.to, params: {} }">
                                <v-list-item-content class="pl-6 py-5">
                                    <v-list-item-title class="text-16-60">{{item.text}}</v-list-item-title>
                                </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
            <v-main class="bg-light h-100">
                <v-container fluid class="h-100">
                    <transition name="fade" mode="out-in" appear>
                        <div class="">
                            <div class="d-flex justify-space-between align-center d-block d-lg-none">
                                <router-link :to="{ name: 'frontend.home', params: {} }">
                                    <img src="@/assets/logo/newblack.svg" alt="" class="expanded" width="100">
                                </router-link>
                                <v-btn depressed large class="secondary px-4 text-14 text-transform-none" :to="{name: 'account.quote'}">Start new quote</v-btn>
                            </div>
                            <div v-if="$store.state.auth.user" class="mt-4 mt-lg-0">
                                <router-link v-if="!$store.state.auth.user.first_name || !$store.state.auth.user.last_name" class="white--text" :to="{ name: 'account.data', params: {} }">
                                    <v-alert
                                        color="#5496f0"
                                        type="warning"
                                        class="mx-lg-10"
                                        >
                                        Please fill your account details
                                    </v-alert>
                                </router-link>
                            </div>
                            <slot class=""/>
                            <div class="fixedbottommenu pt-2 d-block d-lg-none">
                                <div class="d-flex justify-space-between mx-4 mb-2">
                                    <div class="" v-for="(item, i) in mdAndDownMenu()">
                                        <router-link :to="{ name: item.to , params: {} }">
                                            <div class="text-10-45 text-center">
                                                <img :src="getSmMenuIcon(item.icon)" alt="" height="20px">
                                                <div class="text-center menutext" v-html="item.text"></div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </v-container>
            </v-main>
        </div>

    </div>
</template>

<script>
export default {
    props: ['mini', 'expandhover'],
    data: () => ({
        overlay: false,
        current: 0,
        items: [
            {
                to: 'account.dashboard',
                text: 'Home',
                icon: 'home'
            },
            {
                size: 'desktop',
                to: 'account.dashboard',
                text: 'Dashboard'
            },
            {
                size: 'all',
                to: 'account.orders',
                text: 'My orders',
                icon: 'orders'

            },
            {
                size: 'all',
                to: 'account.quotes',
                text: 'My quotes',
                icon: 'quotes'
            },
            {
                size: 'desktop',
                to: 'account.track',
                text: 'Track shipping'
            },
            {
                to: 'account.track',
                text: 'Track',
                icon: 'track'
            },
            {
                size: 'desktop',
                to: 'account.data',
                text: 'Account'
            },
            {
                to: 'account.main',
                text: 'Account',
                icon: 'account'
            },
            {
                size: 'all',
                to: 'account.notification',
                text: 'Notifications',
            },
            // {
            //     to: 'account.dashboard6',
            //     text: 'Payment'
            // },
            // {
            //     to: 'account.dashboard7',
            //     text: 'Notifications'
            // },
            {
                size: 'desktop',
                to: 'account.logout',
                text: 'Sign out'
            },
        ]
    }),
    mounted(){
        if(this.expandhover)
            this.overlay = true;
        window.scrollTo(0, 0)
    },
    methods: {
        mdAndDownMenu(){
            return this.items.filter( function(item){
                if(item.icon) return item;
            })
        },
        lgAndUpMenu(){
            return this.items.filter( function(item){
                if(item.size == 'desktop' || item.size == 'all') return item;
            })
        },
        getSmMenuIcon(icon, active = false){
            if(active)
                return require('@/assets/icons/svg/xs-account/'+ icon +'-active.svg');
            return require('@/assets/icons/svg/xs-account/'+ icon +'.svg');
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
.accountmenu{
    box-shadow: 0px 4px 25px rgba(13, 20, 109, 0.05);
    .v-navigation-drawer__border{
        display: none;
    }
    .menuactive, .v-list-item:hover{
        background: #F2F8FF;
    }
    .v-list-item:not(.menuactive){
        .router-link-active{
            text-decoration: none;
        }
        .v-list-item__title{
            color: $dark;
        }
    }
    .v-list-item:not(.menuactive):hover::before{
        opacity: 0;
    }
    .menuactive::before{
        opacity: 1!important;
        width: 3px;
        background-color: #5496F0;
        border-radius: 0;

    }
    .menuactive{
        .v-list-item__title{
            color: #5496F0;
        }
    }
    .v-navigation-drawer{
        transition: 0.5s;
    }
    .v-navigation-drawer--mini-variant{
        background: $blue_first;
        .v-item-group, .expanded{
            display: none;

        }
    }
    .v-navigation-drawer.v-navigation-drawer--is-mouseover{
        .mini{
            display: none;
        }
    }
}
.fixedbottommenu{
    position: fixed;
    width: 100%;
    background: white;
    bottom: 0;
    left: 0;
    right: 0;
    .menutext{
        color: rgba(31,31,31, 0.5)
    }
}
</style>
