<template>
    <v-stepper-content step="3" class="px-0">
        <v-form lazy-validation ref="form3">
            <h2 class="text-28-60 my-4">Fill up the fields about item details</h2>
            <div v-for="(item, key) in form.items">
                <div v-if="key != item_active_now" class="border pa-5 d-flex flex-column flex-md-row justify-space-between my-3">
                    <div class="text-14-60">
                        {{item.description}}
                    </div>
                    <div class="blue-text" @click="item_active_now = key">
                        Edit item
                    </div>
                </div>
                <v-row v-if="key == item_active_now">
                    <v-col cols="12" lg="12">
                        <div class="">
                            <div class="d-flex justify-space-between">
                                <label for="" class="text-14-45">Item description</label>
                                <div v-if="key > 0" class="blue-text" @click="form.items.splice(key, 1)">
                                    Remove item
                                </div>
                            </div>
                            <v-text-field
                                v-model="item.description"
                                placeholder="Item description"
                                background-color="silver"
                                outlined
                                flat
                                hide-details
                                :rules="requiredRules"
                                required
                            ></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="12">
                        <div class="">
                            <label for="" class="text-14-45">Condition of the item</label>
                            <v-radio-group
                                v-model="item.is_new"
                                hide-details
                                :rules="requiredRules"
                                required
                            >
                                <v-row>
                                    <v-col cols="6" md="3" v-for="(condition, key) in conditions">
                                        <v-radio :value="key" :label="condition"></v-radio>
                                    </v-col>
                                </v-row>
                            </v-radio-group>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="12">
                        <div class="w-50">
                            <label for="" class="text-14-45">Packaging type</label>
                            <v-select
                                v-model="item.type"
                                :items="item_types"
                                background-color="silver"
                                label="Choose packaging type"
                                outlined
                                flat
                                solo
                                hide-details
                                :rules="requiredRules"
                                required
                            ></v-select>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <div class="">
                            <label for="" class="text-14-45">Length of crate</label>
                            <v-text-field
                                v-model="item.length"
                                placeholder="Length"
                                suffix="inches"
                                background-color="silver"
                                outlined
                                flat
                                hide-details
                                :rules="requiredRules"
                                required
                            ></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <div class="">
                            <label for="" class="text-14-45">Width of crate</label>
                            <v-text-field
                                v-model="item.width"
                                placeholder="Width"
                                suffix="inches"
                                background-color="silver"
                                outlined
                                flat
                                hide-details
                                :rules="requiredRules"
                                required
                            ></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <div class="">
                            <label for="" class="text-14-45">Height of crate</label>
                            <v-text-field
                                v-model="item.height"
                                placeholder="Height"
                                suffix="inches"
                                background-color="silver"
                                outlined
                                flat
                                hide-details
                                :rules="requiredRules"
                                required
                            ></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <div class="">
                            <label for="" class="text-14-45">Weight of 1 crate</label>
                            <v-text-field
                                v-model="item.weight"
                                placeholder="Weight"
                                suffix="pounds"
                                background-color="silver"
                                outlined
                                flat
                                hide-details
                                :rules="requiredRules"
                                required
                                @change="calculateWeight"
                            ></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <div class="">
                            <label for="" class="text-14-45">Number of crates</label>
                            <v-text-field
                                v-model="item.quantity"
                                placeholder="Number of crates"
                                suffix="crate"
                                background-color="silver"
                                outlined
                                flat
                                hide-details
                                :rules="requiredRules"
                                required
                                @change="calculateWeight"
                            ></v-text-field>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <p>Total shipment weight: {{total_lbs}} pounds</p>
            <div class="silver d-flex justify-center my-10">
                <div class="py-10 text-center" @click="addItem">
                    <v-icon class="blue-text">mdi-plus-circle-outline</v-icon>
                    <p class="mb-0 text-14-45 blue-text">Add another item</p>
                </div>
            </div>
            <v-row class="mb-7">
                <v-col cols="12" lg="12">
                    <div class="mb-4">
                        <label for="" class="text-14-45">Does this shipment contain hazardous items?</label>
                        <v-radio-group v-model="form.hazardous" hide-details>
                            <div class="d-flex">
                                <v-radio class="mb-0 mr-3" :value="0" label="No"></v-radio>
                                <v-radio :value="1" label="Yes"></v-radio>
                            </div>
                        </v-radio-group>
                    </div>
                    <div class="">
                        <label for="" class="text-14-45">Is this shipment temperature sensitive?</label>
                        <v-radio-group v-model="form.temperature" hide-details>
                            <div class="d-flex">
                                <v-radio class="mb-0 mr-3" :value="0" label="No"></v-radio>
                                <v-radio :value="1" label="Yes"></v-radio>
                            </div>
                        </v-radio-group>
                    </div>
                </v-col>
            </v-row>
            <v-btn x-large class="previous px-md-16 py-md-8 text-14 text-transform-none mr-5" depressed @click="$emit('prev')"><v-icon>mdi-chevron-left</v-icon> Back</v-btn>
            <v-btn x-large class="secondary px-md-12 py-md-8 text-14 text-transform-none" depressed @click="$emit('next')">Next step <v-icon>mdi-chevron-right</v-icon></v-btn>
        </v-form>
    </v-stepper-content>
</template>

<script>
import Vue from 'vue';
import ValidationMixin from '@/mixins/validationMixin.js';

export default {
    props: ['form', 'item_push'],
    mixins: [
        ValidationMixin
    ],
    data: () => ({
        item_active_now: null,
        total_lbs: 0,
        conditions: {
            1: 'New item',
            0: 'Used item'
        },
        item_types: ['LTL', 'Pallets', 'TL', 'Pallet (48"x40")', 'Pallet (48"x48")', 'Box', 'Crate', 'Bundle', 'Drum', 'Roll', 'Bale'],
    }),
    mounted(){
        if(!this.form.items[0])
            this.addItem();
    },
    methods: {
        addItem(){
            this.form.items.push(Vue.util.extend({}, this.item_push));
            this.item_active_now = this.form.items.length - 1;
        },
        calculateWeight(){
            this.total_lbs = 0;

            this.form.items.map(item => {
                if(item.quantity && item.weight)
                    this.total_lbs += parseInt(item.quantity * item.weight);
            })
        }
    }
}
</script>

<style lang="css" scoped>
</style>
