<template>
    <v-stepper-content step="99" class="px-0">
        <v-form lazy-validation ref="form99" v-if="$store.state.auth.user">
            <h2 class="text-28-60 my-4">User information</h2>
            <v-row>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">First Name</label>
                        <v-text-field v-model="$store.state.auth.user.first_name" background-color="silver" outlined flat hide-details placeholder="John"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Last Name</label>
                        <v-text-field v-model="$store.state.auth.user.last_name" background-color="silver" outlined flat hide-details placeholder="Wick"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Email</label>
                        <v-text-field v-model="$store.state.auth.user.email" background-color="silver" outlined flat hide-details placeholder="your@email.com"></v-text-field>
                    </div>
                </v-col>
            </v-row>
            <h2 class="text-28-60 my-5">Company information</h2>
            <v-row>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Company Name</label>
                        <v-text-field v-model="$store.state.auth.user.company_name" background-color="silver" outlined flat hide-details placeholder="Your Company Name"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Address</label>
                        <v-text-field v-model="$store.state.auth.user.address" background-color="silver" outlined flat hide-details placeholder="Naples, FL 34101"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Address 2 (optional)</label>
                        <v-text-field v-model="$store.state.auth.user.address2" background-color="silver" outlined flat hide-details placeholder="(optional)"></v-text-field>
                        <p class="text-14-45 silver--text mt-2">Apartment, unit, suite, etc.</p>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">City or postal code</label>
                        <v-text-field v-model="$store.state.auth.user.id_city" background-color="silver" outlined flat hide-details placeholder="Amelia Court House, VA - 23002"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Phone Number</label>
                        <v-text-field v-model="$store.state.auth.user.phone" background-color="silver" outlined flat hide-details placeholder="Phone number"></v-text-field>
                    </div>
                </v-col>
            </v-row>
            <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none" @click="$emit('saveUser')" depressed>Next step <v-icon>mdi-chevron-right</v-icon></v-btn>
        </v-form>
    </v-stepper-content>
</template>

<script>
import CityAutocomplete from '@/components/base/CityAutocomplete';
import ValidationMixin from '@/mixins/validationMixin.js';

export default {
    props: ['form'],
    components: {
        CityAutocomplete
    },
    mixins: [
        ValidationMixin
    ],
    data: () => ({
        hours: [],
        from_time: '',
        to_time: '',
        change: false
    }),
    mounted(){
        this.makeHours()
    },
    computed: {
        computedDateFormatted () {
            return this.formatDate(this.form.from.date)
        },
    },
    watch: {
        from_time (val) {
            this.setTimeInterval();
        },
        to_time (val) {
            this.setTimeInterval();
        }
    },
    methods: {
        makeHours(){
            let type = ['AM', 'PM'];
            var i;
            type.forEach((val) => {
                // for(i = 1; i <= 12; i++){
                //     console.log( i + ' ' + val);
                // }
                for (i = 1; i <= 12; i++) {
                  this.hours.push(i + ' ' + val)
                }
            })
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        setTimeInterval(){
            console.log('intrval');
            if(this.from_time && !this.to_time)
                this.form.from.time = this.from_time;
            if(!this.from_time && this.to_time)
                this.form.from.time = this.to_time;
            if(this.from_time && this.to_time)
                this.form.from.time = this.from_time + '-' + this.to_time;
        }
    }
}
</script>
