<template>
    <layout :mini="false" :expandhover="false">
        <v-container class="calculatorback mb-16">
            <calculator :setStep="e1" :redir="false"/>
        </v-container>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/AccountLayout.vue'
import Calculator from '@/components/pages/Calculator.vue'
export default {
    middleware: 'auth',
    components: {
        Layout,
        Calculator
    },
    data: () => ({
        e1: null,
        id: null,
        tabs: [
            'Pickup',
            'Delivery',
            'Value',
            'Coverage',
            'Payment',
        ],
        form: null,
    }),
    created(){

    },
    methods: {

    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
.calculator{
    .v-card{
        .v-input, textarea{
            margin-top: 7px;
        }
        .v-text-field{
            fieldset{
                border: none;
            }
            input::placeholder{
                font-size: 14px !important;
                color: $silver-dark !important;
            }
        }
    }
    .stepper-header{
        .line{
            border-bottom: 2px solid #5496F0;
        }
        .dot-line{
            border-bottom: 2px dashed #CDE0FF;
        }
    }
    .v-stepper__content{
        .previous{
            background-color: #ECECEC;
            color: $silver-dark;
        }
    }
}
</style>
