<template>
    <v-stepper-content step="10" class="px-0">
        <h2 class="text-28-60 my-4">Would you like Shipper's <br/>Interest Insurance coverage?</h2>
        <p class="text-14">Carriers assume Cargo legal liability however, the coverage amounts will vary and are subject to limitations. Therefore, many shippers purchase their own insurance policy, known as Shipper's Interest Insurance This coverage is based upon the product's value</p>
        <div class="mb-8">
            <div class="border pa-5 my-3">
                <div class="d-flex justify-space-between">
                    <div class="">
                        <div class="text-18-75">
                            Shipper's Interest Insurance
                        </div>
                        <v-radio-group v-model="form.insurance" hide-details>
                            <v-radio v-for="(condition, key) in insurance" :value="key" :label="condition"></v-radio>
                        </v-radio-group>
                    </div>
                    <div class="">
                        <div class="text-28-75 text-right">
                            $35.85
                        </div>
                        <div class="text-14">
                            Total with shipment: $220.96
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none" depressed @click="$emit('makeOrder')">Next step <v-icon>mdi-chevron-right</v-icon></v-btn>
    </v-stepper-content>
</template>

<script>
export default {
    props: ['form'],
    data: () => ({
        insurance: {
            1: 'Yes, add to my shipment',
            0: 'No, I don\'t need insurance'
        },
    })
}
</script>
