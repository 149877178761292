<template>
    <v-stepper-content step="9" class="px-0">
        <v-form lazy-validation ref="form9">
            <h2 class="text-28-60 my-4">Let's talk product protection</h2>
            <p class="text-14">In case of cargo loss or damage, the value of the product <br/>is required to determine your maximum benefit amount</p>
            <div class="mb-8">
                <div class="" v-for="(item, key) in form.items">
                    <div class="border pa-5 my-3">
                        <div class="w-70">
                            <div class="d-flex justify-space-between">
                                <div class="">
                                    <div class="text-14-60">
                                        {{item.description}}
                                    </div>
                                    <div class="text-14">
                                        {{ item.quantity }} Crate, Total {{ item.quantity * item.weight}} lbs, Class {{item.class}}
                                    </div>
                                </div>
                            </div>
                            <div class="mt-7">
                                <label for="" class="text-14-45">Total product value (USD)</label>
                                <v-text-field
                                    v-model="item.value"
                                    placeholder="Value"
                                    background-color="silver"
                                    suffix="USD"
                                    outlined
                                    flat
                                    hide-details="auto"
                                    :rules="requiredRules"
                                    required
                                ></v-text-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none" depressed @click="$emit('next')">Next step <v-icon>mdi-chevron-right</v-icon></v-btn>
        </v-form>
    </v-stepper-content>
</template>

<script>
import ValidationMixin from '@/mixins/validationMixin.js';

export default {
    props: ['form'],
    mixins: [
        ValidationMixin
    ],
}
</script>
