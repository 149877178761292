<template>
    <v-stepper-content step="8" class="px-0">
        <v-form lazy-validation ref="form8">
            <h2 class="text-28-60 my-4">Great! Now tell us a bit more about the delivery location</h2>
            <v-row>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Company or individual name</label>
                        <v-text-field
                            v-model="form.to.name"
                            placeholder="Company or individual name"
                            background-color="silver"
                            outlined
                            flat
                            hide-details="auto"
                            :rules="requiredRules"
                            required
                        ></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Adress</label>
                        <v-text-field
                            v-model="form.to.address"
                            placeholder="Adress"
                            background-color="silver"
                            outlined
                            flat
                            hide-details="auto"
                            :rules="requiredRules"
                            required
                        ></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Address 2 (optional)</label>
                        <v-text-field v-model="form.to.address2" placeholder="Address 2 (optional)" background-color="silver" outlined flat hide-details="auto"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <div class="d-flex justify-space-between">
                            <label for="" class="text-14-45">City or postal code</label>
                            <span v-if="!change" @click="change = true" class="blue-text text-14-45" style="cursor:pointer">Change</span>
                        </div>
                        <v-text-field v-if="!change" v-model="form.to.city_label" placeholder="City or postal code" background-color="silver" outlined flat hide-details="auto" readonly></v-text-field>
                        <city-autocomplete
                            v-if="change"
                            v-model="form.to.geoModel"
                            :items="form.to.geoItems"
                            :search-input.sync="form.to.geoSearch1"
                        />
                    </div>
                </v-col>
            </v-row>
            <div class="mb-8">
                <label for="" class="text-14-45">Special instructions (optional)</label>
                <v-textarea
                    v-model="form.to.instruction"
                    background-color="silver"
                    rows="3"
                    outlined
                    hide-details="auto"
                    flat
                ></v-textarea>
            </div>
            <h2 class="text-28-60 my-4">Contact person</h2>
            <v-row>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Contact name</label>
                        <v-text-field
                            v-model="form.to.contact_name"
                            placeholder="Contact name"
                            background-color="silver"
                            outlined
                            flat
                            hide-details="auto"
                            :rules="requiredRules"
                            required
                        ></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Phone</label>
                        <v-text-field
                            v-model="form.to.contact_phone1"
                            placeholder="Phone"
                            background-color="silver"
                            outlined
                            flat
                            hide-details="auto"
                            :rules="phoneRules"
                            required
                        ></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Email</label>
                        <v-text-field
                            v-model="form.to.contact_email"
                            placeholder="Email"
                            background-color="silver"
                            outlined
                            flat
                            hide-details="auto"
                            :rules="emailRules"
                            required
                        ></v-text-field>
                    </div>
                </v-col>
            </v-row>
            <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none" @click="$emit('next')" depressed>Next step <v-icon>mdi-chevron-right</v-icon></v-btn>
        </v-form>
    </v-stepper-content>
</template>

<script>
import ValidationMixin from '@/mixins/validationMixin.js';
import CityAutocomplete from '@/components/base/CityAutocomplete';
export default {
    props: ['form'],
    components: {
        CityAutocomplete
    },
    mixins: [
        ValidationMixin
    ],
    data: () => ({
        change: false
    }),
}
</script>
