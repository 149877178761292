<template>
    <v-stepper-content step="4" class="px-0">
        <h2 class="text-28-60 my-4">We calculated your <br/> dimensional freight class</h2>
        <p class="text-14">We estimate this to be your freight class based <br/>on the weight and dimensions you provided</p>
        <p class="blue-text" @click="dialog_class = true">What is a freight class?</p>
        <div class="mb-10">
            <div class="" v-for="(item, key) in form.items">

                <div v-if="item_saved_class.indexOf(key) > -1 && item.class != null" class="border pa-5 d-flex flex-column flex-md-row justify-space-between my-3">
                    <div class="">
                        <div class="text-28-75">
                            Class {{ item.class}}
                        </div>
                        <div class="text-14-60">
                            {{item.description}}
                        </div>
                    </div>

                    <div class="blue-text" @click="item_saved_class.splice(key, 1)">
                        Edit item
                    </div>
                </div>
                <div v-else class="border pa-5 my-3">
                    <div class="d-flex justify-space-between">
                        <div class="">
                            <div class="text-28-75">
                                Class {{ item.class}}
                            </div>
                            <div class="text-14-60">
                                {{item.description}}
                            </div>
                        </div>
                        <div v-if="item.class" class="blue-text" @click="item_saved_class.push(key)">
                            Save
                        </div>
                    </div>
                    <v-row>
                        <v-col cols="12" lg="6">
                            <div class="">
                                <label for="" class="text-14-45">Freight class</label>
                                <v-select v-model="item.class" :items="item_classes" background-color="silver" label="Choose packaging type" outlined flat solo hide-details></v-select>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <div class="">
                                <label for="" class="text-14-45">NMFC# for resf (optional)</label>
                                <v-text-field v-model="item.nmfc" background-color="silver" outlined flat hide-details></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
        <v-btn x-large class="previous px-md-16 py-md-8 text-14 text-transform-none mr-5" depressed @click="$emit('prev')"><v-icon>mdi-chevron-left</v-icon> Back</v-btn>
        <v-btn x-large class="secondary px-md-12 py-md-8 text-14 text-transform-none" depressed @click="$emit('getQuote')">Next step <v-icon>mdi-chevron-right</v-icon></v-btn>
        <v-dialog
            v-model="dialog_class"
            max-width="650"
            overlay-color="#3D475B"
            overlay-opacity="0.56"
            :transition="false"
        >
            <v-card class="pa-6" flat>
                <v-card-title class="text-28-60">
                    What is freight class
                </v-card-title>
                <v-card-text>
                    U.S. less than truckload (LTL) carriers use freight classes to categorize LTL freight. Freight classes are defined by the National Motor Freight Traffic Association (NMFTA)
                </v-card-text>
                <v-card-title class="text-28-60">
                    Why it's important
                </v-card-title>
                <v-card-text>
                    Freight classes help ensure you receive a fair price from carriers and may establish the applicable limit of liability in the case of loss or damage
                </v-card-text>
                <v-card-title class="text-28-60">
                    How it's calculated
                </v-card-title>
                <v-card-text>
                    Freight classes are calculated based on four characteristics: density, handling, stowability and liability.
                    If you don't know yours, no worries. We can use the information you already shared to provide an estimated class in the next step
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-stepper-content>
</template>

<script>
export default {
    props: ['form'],
    data: () => ({
        item_saved_class: [],
        item_classes: [
            100, 250,
        ],
        dialog_class: false,
    })
}
</script>

<style lang="scss">
.v-dialog.v-dialog--active{
    box-shadow: none;
}

</style>
