<template>
    <v-stepper-content step="6" class="px-0">
        <div class="d-flex justify-space-between">
            <h2 class="text-28-60 my-4">Select a carrier <br/>to move your shipment</h2>
            <div class="d-none d-md-block">
                <div class="silver--text">
                    Rates starting at
                </div>
                <div class="text-28-75">
                    $14.15
                </div>
            </div>
        </div>
        <div class="silver pa-6 mb-5">
            <div class="d-flex justify-space-between">
                <div class="text-18-75">
                    {{form.from.city}} <v-icon>mdi-chevron-right</v-icon> {{form.to.city}}
                </div>
                <div class="blue-text d-none d-md-block" @click="$emit('backTo', 3)">
                    Edit
                </div>
            </div>

            <div class="d-flex justify-space-between mt-4 flex-column">
                <div v-for="(item, key) in form.items" class="w-70">
                    <div class="text-14-60 mb-1" v-html="item.description"></div>
                    <div class="text-14 mb-5">
                        {{ item.quantity }} Crate, Total {{ item.quantity * item.weight}} lbs, Class {{item.class}}
                    </div>
                </div>
            </div>
        </div>
        <div class="">
            <div class="">
                Sort by:
            </div>
            <div class="d-flex mt-3">
                <div class="badge px-4 py-2" :class="{'active': sort_tab == 1}" @click="sort_tab = 1">
                    Lowest price
                </div>
                <div class="badge px-4 py-2 ml-3" :class="{'active': sort_tab == 2}" @click="sort_tab = 2">
                    Quickest delivery
                </div>
            </div>
        </div>
        <div class="mt-8">
            <div class="border py-4 px-6">
                <div class="d-block d-md-flex justify-space-between align-center">
                    <div class="text-18-75">
                        Freight Crew Inc.
                    </div>
                    <div class="text-28-75 d-none d-md-block">
                        $288.85
                    </div>
                </div>
                <div class="d-none d-md-flex justify-space-between mt-6">
                    <div class="">
                        <div class="text-14">
                            Estimated transit
                        </div>
                        <div class="text-14-60">
                            1 - 2 business days
                        </div>
                    </div>
                    <div class="">
                        <div class="text-14">
                            Shiping type
                        </div>
                        <div class="text-14-60">
                            Less than truckload (LTL)
                        </div>
                    </div>
                    <div class="text-right ">
                        <v-btn large class="secondary px-8 py-3 text-14 text-transform-none" depressed @click="$emit('makeQuote')">Select</v-btn>
                    </div>
                </div>
                <v-row class="d-md-none">
                    <v-col cols="6">
                        <div class="text-14">
                            Estimated transit
                        </div>
                        <div class="text-14-60">
                            1 - 2 business days
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="text-14">
                            Shiping type
                        </div>
                        <div class="text-14-60">
                            Less than truckload (LTL)
                        </div>
                    </v-col>
                </v-row>
                <div class="d-flex justify-space-between d-md-none">
                    <div class="text-28-75 d-block d-md-none">
                        $288.85
                    </div>
                    <v-btn large class="secondary px-8 py-3 text-14 text-transform-none" depressed @click="$emit('makeQuote')">Select</v-btn>
                </div>
            </div>
        </div>
    </v-stepper-content>
</template>

<script>
export default {
    props: ['form'],
    data: () => ({
        sort_tab: 1,

    })
}
</script>
