<template>
    <v-stepper-content step="1" class="px-0">
        <v-form lazy-validation ref="form1">
            <h2 class="text-28-60 my-4">Fill up the fields about pickup</h2>
            <v-row class="mb-4">
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">City or postal code</label>
                        <v-autocomplete
                            v-model="form.from.geoModel"
                            :items="form.from.geoItems"
                            :search-input.sync="form.from.geoSearch"
                            background-color="#EDF2F9"
                            outlined
                            class="mt-2"
                            hide-no-data
                            hide-details
                            hide-selected
                            item-text="label"
                            placeholder="Pickup city"
                            return-object
                            :rules="requiredRules"
                            required
                        ></v-autocomplete>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="">
                        <label for="" class="text-14-45">Requested loading date</label>
                        <v-menu
                            v-model="form.from.datepicker"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="computedDateFormatted"
                                    class="mt-2"
                                    outlined
                                    background-color="silver"
                                    placeholder="08/23/2020"
                                    append-icon="mdi-calendar-month"
                                    hide-details
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="form.from.date"
                                no-title
                                @input="form.from.datepicker = false"
                            ></v-date-picker>
                        </v-menu>
                    </div>
                </v-col>
                <v-col cols="12" lg="12">
                    <label for="" class="text-14-45">Type of location</label>
                    <v-radio-group
                        v-model="form.from.location_type"
                        :rules="requiredRules"
                        required
                    >
                        <v-row>
                            <v-col cols="12" lg="4" v-for="item in location_types">
                                <div class="d-flex flex-column align-center text-center px-4 py-6 h-100 selectitem"
                                    :class="{'active': (location_types[form.from.location_type] && location_types[form.from.location_type].key == item.key)}">

                                    <v-radio :value="item.key"></v-radio>
                                    <h4 class="text-14-60" v-html="item.name"></h4>
                                    <p class="text-14 mb-0" v-html="item.desc"></p>
                                </div>
                            </v-col>
                        </v-row>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" lg="12">
                    <label for="" class="text-14-45">Additional services</label>
                    <v-row>
                        <v-col v-if="item.only != 'down'" cols="12" lg="4" v-for="item in additional_services">
                            <div class="d-flex flex-column align-center text-center px-4 py-6 h-100 selectitem"
                                :class="{'active': (form.from.additional_services.includes(item.key))}">
                                <v-checkbox v-model="form.from.additional_services" :value="item.key"></v-checkbox>
                                <h4 class="text-14-60" v-html="item.name"></h4>
                                <p class="text-14 mb-0" v-html="item.desc"></p>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-btn x-large class="secondary px-md-12 py-md-8 text-14 text-transform-none" @click="$emit('next')" depressed>Next step <v-icon>mdi-chevron-right</v-icon></v-btn>
        </v-form>
    </v-stepper-content>
</template>

<script>
import ValidationMixin from '@/mixins/validationMixin.js';
export default {
    props: ['form'],
    mixins: [
        ValidationMixin
    ],
    data: () => ({
        location_types: {
            1: {
                key: 1,
                name: 'Business',
                desc: 'Commercial buildings and areas',
            },
            2: {
                key: 2,
                name: 'Residential',
                desc: 'Home and home businesses'
            },
            3: {
                key: 3,
                name: 'Drop off at a carrier terminal',
                desc: 'Save money and skip the pickup services'
            }
        },
        additional_services: {
            1: {
                key: 1,
                only: 'all',
                name: 'Lift gate',
                desc: 'When the location doesn\'t have a loading dock and your shipment is too heavy to lift without assistance'
            },
            2: {
                key: 2,
                only: 'up',
                name: 'Pickup inside the location',
                desc: 'When the driver needs to move the shipment from somewhere other than directly behind the truck'
            },
            3: {
                key: 3,
                only: 'up',
                name: 'Limited access',
                desc: 'A non-residential location that may be difficult for a truck to access. i.e. farms, construction site, church, strip mall, school, etc.'
            },
            4: {
                key: 4,
                only: 'down',
                name: 'Delivery inside the location',
                desc: 'When the driver needs to move the shipment from somewhere other than directly behind the truck'
            },
            5: {
                key: 5,
                only: 'down',
                disabled: true,
                name: 'Appointment Fee',
                desc: 'When the carrier is required to contact final delivery location for specific appointment window'
            },
            6: {
                key: 6,
                only: 'down',
                name: 'Residential delivery',
                desc: 'When the location is a residential address'
            },
        },
    }),
    computed: {
        computedDateFormatted () {
            return this.formatDate(this.form.from.date)
        },
    },
    methods: {
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
    }
}
</script>

<style lang="scss">
.calculator{
    .v-icon.notranslate{
        color: #CDE0FF;
    }
    .primary--text.v-icon.notranslate{
        color: #5496F0!important;
    }
}
</style>
